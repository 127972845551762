<template>
  <div
    class="customArticle_detail_container"
    :style="{ padding: !data.html ? '16px' : '0' }"
  >
    <!-- <ItemScroll ref="ItemScroll "> -->
    <div>
      <div class="customArticle_detail_list_title">
        {{ data.zh_title }}
      </div>
      <div class="customArticle_detail_list_time" v-if="data.wx_official_name">
        <img class="logo" :src="data.wx_official_logo" alt="" />
        <div class="info">
          <div class="info_left">
            <!-- <a :href="data.wx_official_url">{{ data.wx_official_name }}</a> -->
            <div class="title">{{ data.wx_official_name }}</div>
            <div class="desc" style="margin-left: 8px">
              {{ data.email_push_time }}
            </div>
          </div>
          <div class="follow" @click="openOfficial(data.wx_official_url)">
            关注
          </div>
        </div>
      </div>
      <div v-html="data.html" v-if="data.html"></div>
      <div
        v-else
        class="customArticle_detail_field_row"
        v-for="(tweetItem, tweetIndex) in data.content"
        :key="tweetIndex"
      >
        <div class="customArticle_detail_row_value">
          <!-- 图片 -->
          <img
            class="customArticle_detail_row_value_img"
            v-if="tweetItem.type == 1"
            :src="tweetItem.content"
            @load="initScroll"
            @click="selectItem(tweetIndex)"
          />
          <!-- 文字 -->
          <div
            class="customArticle_detail_row_value_title"
            v-if="tweetItem.type == 0"
            @click="selectItem(tweetIndex)"
            v-html="tweetItem.content"
          ></div>
          <!-- <div
              class="customArticle_detail_row_value_title"
              v-if="tweetItem.type == 0"
              @click="selectItem(tweetIndex)"
            >
              {{ tweetItem.content }}
            </div> -->
          <!-- 语音 -->
          <div
            class="customArticle_detail_audio"
            v-if="tweetItem.type == 2"
            @click="selectItem(tweetIndex)"
          >
            专家音频
            <el-icon :size="16" v-show="!tweetItem.play"><VideoPlay /></el-icon>
            <el-icon :size="16" v-show="tweetItem.play"><VideoPause /></el-icon>
            <audio
              @ended="playVideoEnd(tweetIndex)"
              :ref="`module${tweetIndex}`"
              :src="tweetItem.content"
            ></audio>
          </div>
          <!-- 视频 -->
          <div class="customArticle_detail_video" v-if="tweetItem.type == 3">
            <video
              @click.stop="selectItem(tweetIndex)"
              @play="listenPlay(tweetIndex)"
              @ended="playVideoEnd(tweetIndex)"
              :ref="`module${tweetIndex}`"
              :src="tweetItem.content"
              x5-video-player-type="h5-page"
              preload="metadata"
              playsinline="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              x5-video-orientation="portraint"
              controls="controls"
            ></video>
          </div>
        </div>
      </div>
      <div class="customArticleDetail-footer">
        <div class="footer_icon_block">
          <img :src="require(`@/assets/images/guzhuanyi/read.png`)" alt="" />
          <div class="title">
            {{ countInfo.read_count }}
          </div>
        </div>
        <div class="footer_icon_block" @click="handlePraise">
          <img
            v-if="countInfo.is_like"
            :src="require(`@/assets/images/guzhuanyi/gooding.png`)"
            alt=""
          />
          <img
            v-else
            :src="require(`@/assets/images/guzhuanyi/good.png`)"
            alt=""
          />
          <div class="title" v-if="countInfo.like_count">
            {{ countInfo.like_count }}
          </div>

          <div v-else class="title">点赞</div>
        </div>
        <div
          class="footer_icon_block bgColor"
          @click="openOfficial(data.wx_official_url)"
        >
          关注我们
        </div>
        <div class="footer_icon_block bgColor" @click="handleGoHead">
          周刊首页
        </div>

        <div class="footer_icon_block" @click="hanleCollect">
          <img
            v-if="countInfo.is_collect"
            :src="require(`@/assets/images/guzhuanyi/liking.png`)"
            alt=""
          />
          <img
            v-else
            :src="require(`@/assets/images/guzhuanyi/like.png`)"
            alt=""
          />

          <div class="title" v-if="countInfo.collect_count">
            {{ countInfo.collect_count }}
          </div>
          <div class="title" v-else>收藏</div>
        </div>
        <div class="footer_icon_block" @click="handleShare(true)">
          <img :src="require(`@/assets/images/guzhuanyi/sharing.png`)" alt="" />
          <div class="title">分享</div>
        </div>
      </div>
    </div>
    <!-- </ItemScroll> -->

    <div class="share_modal" v-if="showSharePopup" @click="handleShare(false)">
      <img :src="require(`@/assets/images/share.png`)" />
    </div>
  </div>
</template>
<script>
import { ElIcon } from "element-plus";
import { VideoPlay, VideoPause } from "@element-plus/icons";
import weixin from "@/common/weixin";
import ItemScroll from "@/components/unit/ItemScroll";
import mixins from "@/components/unit/mixins";
export default {
  mixins: [mixins],
  name: "customArticle_detail",
  data() {
    return {
      data: {},
      countInfo: {
        collect_count: 0,
        is_collect: 0,
        is_like: 0,
        like_count: 0,
        read_count: 0,
      },
      id: "",
      showSharePopup: false,
      clickIndex: 0,
      scrollIndex: 0,

      titleImg: "",
      literature_type: 0,
    };
  },
  components: {
    ElIcon,
    VideoPlay,
    VideoPause,
    ItemScroll,
  },
  created() {
    let _this = this;
    window.showImage = _this.showImage;
  },

  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "推文详情";
    this.id = this.$route.query.id;
    this.literature_type = this.$route.query.literature_type || 0;
    this.loadData();
    this.initCountInfo();
  },

  methods: {
    showImage(e) {
      let imageSourse = [e.src];
      const $viewer = this.$viewerApi({
        images: imageSourse,
      });
      $viewer.show();
    },
    initCountInfo() {
      let url = this.$tools.getURL(this.$urls.literature.countInfo, {
        id: this.id,
        type: this.$store.state.common.userInfo.id
          ? 0
          : this.$store.state.common.salesInfo.phone
          ? 1
          : 0,
        literature_type: this.literature_type,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.countInfo = res.data;
        })
        .catch(() => {});
    },
    loadData() {
      let url = `${this.$urls.literature.detail}?id=${this.id}&literature_type=${this.literature_type}`;
      this.$axios.get(url).then((res) => {
        let data = res.data;
        if (data.html) {
          this.data = { ...data };
          // this.data.html = data.html;
        } else {
          data.content = data.contents || [];
          data.content.map((x) => {
            x.play = false;
            return x;
          });
          this.data = data;
        }
        document.getElementsByTagName("title")[0].innerHTML =
          this.data.zh_title;
        setTimeout(() => {
          this.initShare();
        }, 1000);
        this.initScroll();
        setTimeout(() => this.initScroll, 2000);
      });
    },
    //跳转周刊首页
    handleGoHead() {
      this.doOperationRecord(this.data.id, 2);
      window.location.href = `${this.$config.host}/#/articleList?periods=${this.data.periods}`;
    },
    //关注公众号
    openOfficial(link) {
      this.doOperationRecord(this.data.id, 3);
      // window.open("https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0NzU2NzM3Nw==&scene=110#wechat_redirect");
      window.open(link);
    },
    selectItem(index) {
      let item = this.data.content[index];
      switch (item.type) {
        case 0:
        case 1:
          if (item.link) window.location.href = item.link;
          break;
        case 2:
        case 3:
          this.playVideo(index);
          break;
      }
    },
    listenPlay(index) {
      this.pauseOther(index);
      this.data.content[index].play = true;
      this.initScroll();
    },
    playVideoEnd(index) {
      this.data.content[index].play = false;
    },
    pauseOther(index) {
      this.data.content.forEach((item, contentIndex) => {
        if (
          [2, 3].includes(item.type) &&
          contentIndex !== index &&
          this.data.content[contentIndex].play
        ) {
          this.$refs[`module${contentIndex}`].pause();
          this.data.content[contentIndex].play = false;
        }
      });
    },
    playVideo(index) {
      this.pauseOther(index);
      if (this.data.content[index].play) {
        this.$refs[`module${index}`].pause();
        this.data.content[index].play = false;
      } else {
        this.$refs[`module${index}`].play();
        this.data.content[index].play = true;
      }
    },
    getClickIndex(key = "clickIndex") {
      return ++this[key] > 10000 ? 0 : this[key];
    },
    initScroll() {
      let currentIndex = this.getClickIndex("scrollIndex");
      setTimeout(() => {
        if (currentIndex != this.scrollIndex) return;
        this.$nextTick(() => {
          this.$refs.ItemScroll && this.$refs.ItemScroll.initScroll();
        });
      }, 1000);
    },
    initShare() {
      let params = {
        title: this.data.head_title,
        description: "",
        url: "",
        logo: this.data.wx_official_logo,
      };
      console.log("params :>> ", params);
      weixin.shareMessage(params);
    },
    // 点击分享
    handleShare(status) {
      this.showSharePopup = status;
      let currentIndex = this.getClickIndex();
      if (!status) return;
      setTimeout(() => {
        if (currentIndex != this.clickIndex) return;
        this.doOperationRecord(this.data.id, 4);
        // this.$axios.post(this.$urls.customArticle.share, {
        //   id: this.data.id,
        //   status: 1,
        // });
      }, 1000);
    },
    // 点赞
    handlePraise() {
      if (
        this.$store.state.common.userInfo.id ||
        this.$store.state.common.salesInfo.phone
      ) {
        let url = this.$tools.getURL(this.$urls.literature.like, {
          id: this.data.id,
          type: this.$store.state.common.userInfo.id
            ? 0
            : this.$store.state.common.salesInfo.phone
            ? 1
            : 0,
        });
        let currentIndex = this.getClickIndex();
        setTimeout(() => {
          if (currentIndex != this.clickIndex) return;
          this.$axios.get(url).then((res) => {
            this.$tips.success({ text: res.msg });
            this.countInfo.is_like = this.countInfo.is_like ? 0 : 1;
            this.countInfo.like_count += this.countInfo.is_like == 1 ? 1 : -1;
          });
        }, 1000);
      } else {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.data.id,
          },
        });
      }
    },
    //收藏
    hanleCollect() {
      if (
        this.$store.state.common.userInfo.id ||
        this.$store.state.common.salesInfo.phone
      ) {
        let url = this.$tools.getURL(this.$urls.literature.collect, {
          id: this.data.id,
          type: this.$store.state.common.userInfo.id
            ? 0
            : this.$store.state.common.salesInfo.phone
            ? 1
            : 0,
        });
        let currentIndex = this.getClickIndex();
        setTimeout(() => {
          if (currentIndex != this.clickIndex) return;
          this.$axios.get(url).then((res) => {
            this.$tips.success({ text: res.msg });
            this.countInfo.is_collect = this.countInfo.is_collect ? 0 : 1;
            console.log("this.countInfo :>> ", this.countInfo);
            this.countInfo.collect_count +=
              this.countInfo.is_collect == 1 ? 1 : -1;
          });
        }, 1000);
      } else {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.data.id,
          },
        });
      }
    },
  },
};
</script>
<style>
@import "../../../assets/icon/iconfont.css";
.customArticle_detail_container {
  position: relative;
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.customArticle_detail_list_title {
  text-align: left;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  padding-top: 16px;
}
.customArticle_detail_list_time {
  font-size: 16px;
  /* text-align: left; */
  display: flex;
  align-items: center;
  margin: 16px 0 8px 0;
  color: #ccc;
}
.customArticle_detail_list_time a {
  font-size: 16px;
  text-align: left;
  color: #911c61;
  margin-right: 10px;
  text-decoration: none;
}
.customArticle_detail_field_row {
  display: flex;
  flex-direction: column;
}
.customArticle_detail_row_value {
  flex: 1 0 0;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}
.customArticle_detail_row_value_img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: 10px 0;
}
.customArticle_detail_row_value_title {
  line-height: 28px;
  font-size: 15px;
  /* text-indent: 2em; */
  word-break: break-all;
}
.customArticle_detail_video {
  width: 100%;
  object-fit: contain;
}

.customArticle_detail_container audio {
  width: 100%;
}
.customArticle_detail_container .customArticle_detail_audio {
  line-height: 40px;
  text-align: center;
  height: 40px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 4px;
  color: #911c61;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customArticle_detail_container .el-icon {
  margin-left: 4px;
}

.customArticle_detail_footer {
  padding: 16px 0;
}
.customArticle_detail_field_row_read {
  font-size: 16px;
  color: #727272;
  margin-bottom: 8px;
}
.customArticle_detail_field_row_check {
  display: flex;
  justify-content: space-between;
  color: #6e7ea0;
}
.customArticle_detail_field_row_check .customArticle_detail_field_row_check_td {
  display: flex;
}
.customArticle_detail_field_row_check .iconText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
}
.customArticle_detail_field_row_check .iconText:last-child {
  margin-right: 0;
}
.customArticle_detail_field_row_check .iconSize {
  font-size: 24px;
  text-align: center;
  margin-bottom: 4px;
}
.customArticle_detail_field_row_check .active {
  color: #911c61;
}
.customArticle_detail_container .share_modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customArticle_detail_container .share_modal img {
  width: 280px;
  height: 128px;
  position: absolute;
  top: 0;
  right: 0;
}
.customArticle_detail_list_time .logo {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 16px;
  border-radius: 50%;
}
.customArticle_detail_list_time .info {
  flex: 1 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customArticle_detail_list_time .info .info_left {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: flex-end;
}

.customArticle_detail_list_time .info .title {
  font-size: 15px;
  color: #000;
}
.customArticle_detail_list_time .desc {
  font-size: 15px;
  color: #aaa;
}
.customArticle_detail_list_time .info .follow {
  width: 52px;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: #fff;
  background: #1aa6ec;
  text-align: center;
  border-radius: 4px;
}
.customArticleDetail-footer {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  align-items: center;
}
.customArticleDetail-footer .footer_icon_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.customArticleDetail-footer .bgColor {
  background: #1aa6ec;
  color: #fff;
  border-radius: 16px;
  padding: 2px 12px;
}
.customArticleDetail-footer .footer_icon_block img {
  width: 24px;
  height: 24px;
}
.customArticleDetail-footer .footer_icon_block .title {
  color: #1aa6ec;
}
.customArticleDetail-footer .share_modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  right: 0;
  width: 120%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customArticleDetail-footer .share_modal img {
  width: 280px;
  height: 128px;
  position: absolute;
  top: 0;
  right: 0;
}
</style>  